var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "content-with-sidebar",
    { staticClass: "blog-wrapper" },
    [
      _c(
        "b-row",
        { staticClass: "blog-list-wrapper" },
        [
          _vm._l(_vm.blogList, function(blog) {
            return _c(
              "b-col",
              { key: blog.img, attrs: { md: "6" } },
              [
                _c(
                  "b-card",
                  { attrs: { tag: "article", "no-body": "" } },
                  [
                    _c(
                      "b-link",
                      {
                        attrs: {
                          to: {
                            name: "pages-blog-detail",
                            params: { id: blog.id }
                          }
                        }
                      },
                      [
                        _c("b-img", {
                          staticClass: "card-img-top",
                          attrs: { src: blog.img, alt: blog.img.slice(5) }
                        })
                      ],
                      1
                    ),
                    _c(
                      "b-card-body",
                      [
                        _c(
                          "b-card-title",
                          [
                            _c(
                              "b-link",
                              {
                                staticClass:
                                  "blog-title-truncate text-body-heading",
                                attrs: {
                                  to: {
                                    name: "pages-blog-detail",
                                    params: { id: blog.id }
                                  }
                                }
                              },
                              [_vm._v(" " + _vm._s(blog.title) + " ")]
                            )
                          ],
                          1
                        ),
                        _c(
                          "b-media",
                          { attrs: { "no-body": "" } },
                          [
                            _c(
                              "b-media-aside",
                              {
                                staticClass: "mr-50",
                                attrs: { "vertical-align": "center" }
                              },
                              [
                                _c("b-avatar", {
                                  attrs: {
                                    href: "javascript:void(0)",
                                    size: "24",
                                    src: blog.avatar
                                  }
                                })
                              ],
                              1
                            ),
                            _c("b-media-body", [
                              _c("small", { staticClass: "text-muted mr-50" }, [
                                _vm._v("by")
                              ]),
                              _c(
                                "small",
                                [
                                  _c("b-link", { staticClass: "text-body" }, [
                                    _vm._v(_vm._s(blog.userFullName))
                                  ])
                                ],
                                1
                              ),
                              _c(
                                "span",
                                { staticClass: "text-muted ml-75 mr-50" },
                                [_vm._v("|")]
                              ),
                              _c("small", { staticClass: "text-muted" }, [
                                _vm._v(_vm._s(blog.blogPosted))
                              ])
                            ])
                          ],
                          1
                        ),
                        _c(
                          "div",
                          { staticClass: "my-1 py-25" },
                          _vm._l(blog.tags, function(tag, index) {
                            return _c(
                              "b-link",
                              { key: index },
                              [
                                _c(
                                  "b-badge",
                                  {
                                    staticClass: "mr-75",
                                    attrs: {
                                      pill: "",
                                      variant: _vm.tagsColor(tag)
                                    }
                                  },
                                  [_vm._v(" " + _vm._s(tag) + " ")]
                                )
                              ],
                              1
                            )
                          }),
                          1
                        ),
                        _c(
                          "b-card-text",
                          { staticClass: "blog-content-truncate" },
                          [_vm._v(" " + _vm._s(blog.excerpt) + " ")]
                        ),
                        _c("hr"),
                        _c(
                          "div",
                          {
                            staticClass:
                              "d-flex justify-content-between align-items-center"
                          },
                          [
                            _c(
                              "b-link",
                              {
                                attrs: {
                                  to: {
                                    path:
                                      "/pages/blog/" + blog.id + "#blogComment"
                                  }
                                }
                              },
                              [
                                _c(
                                  "div",
                                  {
                                    staticClass:
                                      "d-flex align-items-center text-body"
                                  },
                                  [
                                    _c("feather-icon", {
                                      staticClass: "mr-50",
                                      attrs: { icon: "MessageSquareIcon" }
                                    }),
                                    _c(
                                      "span",
                                      { staticClass: "font-weight-bold" },
                                      [
                                        _vm._v(
                                          _vm._s(_vm.kFormatter(blog.comment)) +
                                            " Comments"
                                        )
                                      ]
                                    )
                                  ],
                                  1
                                )
                              ]
                            ),
                            _c(
                              "b-link",
                              {
                                staticClass: "font-weight-bold",
                                attrs: {
                                  to: {
                                    name: "pages-blog-detail",
                                    params: { id: blog.id }
                                  }
                                }
                              },
                              [_vm._v(" Read More ")]
                            )
                          ],
                          1
                        )
                      ],
                      1
                    )
                  ],
                  1
                )
              ],
              1
            )
          }),
          _c("b-col", { attrs: { cols: "12" } }, [
            _c(
              "div",
              { staticClass: "my-2" },
              [
                _c("b-pagination", {
                  attrs: {
                    align: "center",
                    "total-rows": _vm.rows,
                    "first-number": "",
                    "last-number": "",
                    "prev-class": "prev-item",
                    "next-class": "next-item"
                  },
                  scopedSlots: _vm._u([
                    {
                      key: "prev-text",
                      fn: function() {
                        return [
                          _c("feather-icon", {
                            attrs: { icon: "ChevronLeftIcon", size: "18" }
                          })
                        ]
                      },
                      proxy: true
                    },
                    {
                      key: "next-text",
                      fn: function() {
                        return [
                          _c("feather-icon", {
                            attrs: { icon: "ChevronRightIcon", size: "18" }
                          })
                        ]
                      },
                      proxy: true
                    }
                  ]),
                  model: {
                    value: _vm.currentPage,
                    callback: function($$v) {
                      _vm.currentPage = $$v
                    },
                    expression: "currentPage"
                  }
                })
              ],
              1
            )
          ])
        ],
        2
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }